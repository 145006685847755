//
// Theme style
//
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';

/* Default (Light mode) */
:root {
  --border-color: #f1f1f2;
}

/* Dark mode */
[data-theme=dark] {
  --border-color: #2b2b40 !important;
}
[data-bs-theme=dark] {
  --border-color: #2b2b40 !important;
}

html,
body {
  font-family: 'Poppins', sans-serif !important;
}
div#kt_aside_footer {
  display: none;
}
::-webkit-input-placeholder {
  font-weight: 400 !important;
  font-size: 14px !important;
}

:-ms-input-placeholder {
  font-weight: 400 !important;
  font-size: 14px !important;
}

::placeholder {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.btn {
  font-size: 14px;
}
.btn-pill {
  border-radius: 30px;
  width: 48px;
  height: 48px;
  padding: 0 !important;
}
.gap-y5 {
  gap: 15px 0;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  background-color: #f9f9f9 !important;
  border: 1px solid #f9f9f9 !important;

  padding: 3px 0;
  border-radius: 8px !important;
}
.css-1jqq78o-placeholder {
  color: #4b5675 !important;
  font-weight: 500 !important;
}
.engage-toolbar.d-flex.position-fixed.px-5.fw-bolder.zindex-2.top-50.end-0.transform-90.mt-20.gap-2 {
  display: none !important;
}
.max-w-300px{width: 450px;}
.tagInput-block{
height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 9px;
}
.relative{
  position: relative;
}

// .table.gy-5 th, .table.gy-5 td {
//   padding-top: 1rem !important;
//   padding-bottom: 1rem !important;
// }
td.remove_padding{
  padding: "0px" !important;
}

@media only screen and (max-width: 991px) {
  .w-250px {
    width:100% !important
  }
  .table.gy-4 th, .table.gy-4 td {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}
}

@media only screen and (max-width: 480px) {
.card-header .card-title ,.card-header .card-title .position-relative{width:100%}
}
table th{
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: 500 !important;
}
.fw-bolder {
  font-weight: 600 !important;
}
.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: calc(0.75rem + 1px) calc(1.1rem + 1px);
}
.modal-header h2{margin: 0;}
.tr-bg{background: #f1f1f2;}
.edit-fields {
  font-size: 12px;
  height: 34px;
  min-height: auto !important;
  padding: 8px 8px;
  
  
}
input[type=number].edit-fields{
  padding-right: 3px !important;
}  

.input-group-prepend .input-group-text  {
  border-radius: 6px 0 0 6px;
}
.input-right-borer .input-group-prepend .input-group-text{
  border-radius: 0 6px 6px 0;
}

.input-group .form-control{
  border-radius: 0 6px 6px 0 !important;
}
.input-right-borer.input-group .form-control{
  border-radius: 6px 0 0 6px !important;
}
table tr .input-group {
  width:120px !important
}
.table.gs-0 th:last-child, .table.gs-0 td:last-child {
  padding-right: 1rem;
}


.hide-btn svg{width:20px; opacity: 0.4;}
.hide-btn.hide-btn-svg svg{width:20px; opacity: 1;}

.product-table-wrap .css-13cymwt-control, .css-t3ipsp-control {
  background-color: transparent !important;
  min-height: auto !important;
  padding: 0;
  border: 1px solid var(--bs-gray-300) !important;
}
.product-table-wrap .css-3w2yfm-ValueContainer{padding: 0 4px}
.product-table-wrap .css-b62m3t-container{width:185px}
.channels-icon{align-items: center;}
.channels-icon span img{
  max-width: 28px;
  max-height: 20px;
  object-fit: contain;
}

.product-table-wrap .svg-icon.svg-icon-2x.ms-2 svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.custom-style-modal .modal-dialog{
  min-width: 650px;
}
.custom-style-modal .modal-footer{justify-content: center;border-top:none}

.svg-icon.svg-icon-primary.svg-icon-2x.ms-2 svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.tabcard{margin-top:-40px}
.tabcard .nav.nav-tabs {
  border-bottom: 1px solid #fff;
}
.tabcard .nav-link.active{
background-color: #009ef7;
border:none;
color:#fff
}
.tabcard .nav-link {
  border: none !important;
  padding: 10px 20px;
  font-weight: 600;
}
.tabcard .card-body.py-3{padding: 0}



.image-input-wrapper{
     border-radius: 0.475rem;
    background-repeat: no-repeat;
    background-size: cover;
    border: 3px solid var(--bs-body-bg);
    box-shadow: var(--bs-box-shadow);
}



.image-input {
  margin: 40px 0 20px;
}

.img-size{
  width: 25px;
  height: 25px;
}



.css-1xc3v61-indicatorContainer,.css-15lsz6c-indicatorContainer {
  padding: 8px 5px !important;

}
.product-table-wrap.table-bordered > :not(caption) > * > * {
  padding: 0 0.75rem !important;
}

.product-table-wrap td .w-174px{width:174px;padding:0  !important}
.w-125px{max-width: 125px;}

.product-maintable-wrap{
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
.product-table-wrap.product-maintable-wrap tr th{
  padding: 1rem 0.75rem !important;
}
.product-table-wrap.product-maintable-wrap tr th,.table-sub-bordered tr td{
  border-right: 1px solid var(--border-color) !important;
}
.product-table-wrap.product-maintable-wrap tr th:last-child,.table-sub-bordered tr td:last-child{
  border-right: 1px solid transparent !important
}
.product-maintable-wrap .table-sub-bordered tr td,.product-maintable-wrap .product-table-wrap.product-maintable-wrap tr th{
  border-bottom: 1px solid var(--border-color) !important;
}
.table-child-bordered tr td{ 
  border-bottom: 1px solid var(--border-color) !important;   
  border-right: 1px solid var(--border-color) !important;
}
.table-child-bordered tr td:last-child{
  border-right: 1px solid transparent !important
}
.product-maintable-wrap table tr td > span,.product-maintable-wrap table tr td > div{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.table-sub-bordered tr td.w-wrap-colm:nth-child(2){
  width:125px;
  min-width: 125px;
}
.table-sub-bordered tr td.w-wrap-colm:nth-child(4){
  width:125px;
  min-width: 125px;
}
.table-sub-bordered tr td.w-wrap-colm:nth-child(6){
  width:250px;
  min-width: 250px;
}

.product-maintable-wrap.table:not(.table-bordered) tfoot tr:last-child th, .product-maintable-wrap.table:not(.table-bordered) tfoot tr:last-child td, .product-maintable-wrap.table:not(.table-bordered) tbody tr:last-child th, .product-maintable-wrap.table:not(.table-bordered) tbody tr:last-child td ,.approvel-table td,.approvel-table td{
  border-bottom: 1px solid var(--border-color) !important;
}
.wrap-185px{width:185px}


.table-sub-bordered tr td,.product-table-wrap.product-maintable-wrap tr th{
  border-bottom: 1px solid var(--border-color) !important;
}
.w-60px{
  min-width: 60px;
}
.w-125px {
  min-width: 125px;
}
.w-200px{
  min-width: 200px;
}
.w-150px{
  min-width: 150px;
}

.w-250px {
  min-width: 250px
}
.w-62px {
  max-width: 62px !important;
  // min-width: 62px !important;
}

@media screen and (max-width: 1500px) {
.w-62px {
 max-width: none !important;
  min-width: 99px !important;
  }
}

@media screen and (max-width: 1500px) {
.product-maintable-wrap .product-table-wrap tr th:nth-child(3),.product-table-wrap tr td:nth-child(3){
  width:200px !important;
  min-width: 200px !important;
}
.w-250px {
  min-width: 200px !important;
  width:200px !important
}

.table-sub-bordered tr td.w-wrap-colm:nth-child(6) {
  width: 200px !important;
  min-width: 200px !important;
}

.w-150px {
  min-width: 130px;
  width:130px !important
}

}
.product-table-wrap tr td .custom-width-set{
  min-width: auto !important;
  width:auto !important
}
.channels-tab-wrap .nav-item button img{
  width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    object-fit: contain;
}

/* Dark mode styles */
html[data-bs-theme="dark"] .text-muted, html[data-bs-theme="dark"] .form-check-label,html[data-bs-theme="dark"] .text-gray-500{
  color: #fff !important;
}
html[data-bs-theme="dark"]span svg{
fill : #fff
}
html[data-bs-theme="dark"] span svg *{
fill : #fff
}
html[data-bs-theme="dark"]  .form-check-input{
border-color: #fff;
}

html[data-bs-theme="dark"] .svg-icon-2x.ms-2 svg  *{
  fill:#1e1e2d
}
html[data-bs-theme="dark"] .svg-icon-2x.ms-2 svg circle{
  fill:#fff
}
html[data-bs-theme="dark"] .css-1nmdiq5-menu *{
  background: #000;
}
html[data-bs-theme="dark"] .css-1nmdiq5-menu * >*:hover{
  background: #999;
}
//

.text-end .d-flex.justify-content-end.gap-2.p-0 rect{
  fill: none !important;
}

html[data-bs-theme=dark] .modal-body svg  rect:first-child{
  fill: none !important;
}

.ang-span {
  padding: 8px 2px !important;
  min-width: 30px;
  text-align: center;
  justify-content: center;
}

html[data-bs-theme="dark"] .errror_style{
  background-color: #1e1e2d !important;
}
span.errror_style{
  border: 0 !important;
  background-color: white !important;
}

// .roles_permission_border_background {
//   background-color: #eeee !important;
//   padding: 10px !important;
//   border-radius: 6px !important;
// }

.fv-plugins-message-container .new-fv-help-block span{
  color: var(--bs-danger);
  font-size: 12px;
  font-weight: 400;
}


.page-link{
  transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out !important;
}

li.page-item.active {
  opacity: 1 !important;
  transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out, box-shadow 0s ease-in-out !important;
}

.product-maintable-wrap .d-flex.justify-content-end.gap-2 {
  gap: 5px !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
}
.product-maintable-wrap .d-flex.justify-content-end.gap-2 button{
  margin: 0 !important;
}
.table.gy-5 td.remove_padding{
  padding: "0px" !important;
}

.btn.btn-icon{
  width:34px !important;
  height: 34px !important;
}
.table.gy-4 th, .table.gy-4 td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.form-select.edit-fields{
  background-position: right 4px center;
  background-size: 10px;
  min-width: 125px;
}
.f-12{
  font-size: 12px;
}
.two-line-text{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.remove-displaytwo-line-text{
  overflow: hidden;
  // display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.form-control-sm {
  font-size: 0.75rem;

}
td .form-control-sm {
  font-size: 12px;
 
 }
 
 td .form-control-sm::-ms-input-placeholder {
  font-size: 12px !important;
 }
 
 td .form-control-sm::placeholder {
  font-size: 12px !important;
 }
.accordian-arrow-btn{
  transition: all .9s ease-in-out;
  -webkit-transition: all .9s ease-in-out;
}
.table-row{
  opacity: 0; 
  transition: opacity 0.9s ease-in-out;
}

.check_opacity{
  opacity: 1;
}

.table > :not(caption) > * > * {
  padding: 0 0.75rem;
}
.table.gy-5 th, .table.gy-5 td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.table-responsive{transition: height 5s;}

.css-hudecg-control{
  border-color: transparent !important;
}
.css-yi6fmu-control{
  border-color: transparent !important;
}
.css-t3ipsp-control{
  box-shadow: 0 0 0 1px var(--border-color) !important;
  border-color: transparent !important;
}
